@media only screen and (max-width: 1200px) {

    .wrapper {
        width: 100%;
        padding: 0 2.5% !important;
    }

}

@media only screen and (max-width: 1000px) {

    body {
        font-size: 13px;
    }

    .page-wrapper {
        margin-bottom: 0;
        &:after { display: none; }
    }


    .wrapper {
        padding: 0 2.5% !important;
    }

    .site-header {
        .header-menu {
            .logo {
                width: 300px;
                height: 83px;
            }

            ul.nav-menu {
                li {
                    margin: 0 .5em;
                    a {
                        font-size: .9em;
                    }
                }
            }
        }
    }

    .featured {
        margin-top: 2px;

        .featured-img {
            max-height: 400px;
            &:after { height: 400px; }

            h1 {
                font-size: 2.4em;
                top: 223px;
                margin-left: .5em;
            }

            h2 {
                font-size: 2.2em;
                top: 280px;
                right: 72px;
                margin-left: .75em;
            }

            img { max-height: 400px; }
        }
    }

    .about {
        .about-left {
            margin-right: 2em;
        }
    }

    .page-body {
        font-size: 15px;

        p, div { font-size: 15px; }

        h2 { font-size: 1.8em; }
        h3 { font-size: 1.7em; }
        h4 { font-size: 1.6em; }
        h5 { font-size: 1.4em; }
        h6 { font-size: 1.2em; }
    }

    .references {
        .references-title { font-size: 30px; }
        .more-refs .btn { width: auto; max-width: 90%; }
    }

    .ref-columns {
        .ref {
            width: 32%;
            margin: .75em .5em;

            .ref-img {
                img { min-width: auto; }
            }
        }
    }

    .reference-masonry {
        a {
            width: 23%;
            margin: .5em;
        }
    }

    .title-parallax-wrapper {
        height: 330px;
        .wrapper { height: 158px; }
    }

    h1.page-title {
        font-size: 2.6em;
    }

    h1.reference-title {
        font-size: 2em;
    }

    .map-container {
        height: 250px;
        #map { height: 250px; }
    }

    .site-footer {
        height: auto;
    }

}

@media only screen and (max-width: 800px) {

    .wrapper {
        padding: 0 1.5% !important;
    }

    .qtip {
        display: none !important;
    }

    .site-header {
        .header-top {
            .contact-info {
                font-size: .9em;
                padding-left: 0;
            }

            .division-links {
                a { font-size: .9em; }
            }
        }

        .header-menu {
            ul.nav-menu {
                display: none;
                width: 100%;
                height: 100%;
                align-items: center;
                flex-wrap: wrap;
                position: fixed;
                top: 0;
                right: 0;
                padding: 15% 0;
                z-index: 200;
                background: rgba($blue, .85);

                li {
                    width: 100%;
                    margin: 0;
                    padding: 1.2em;
                    a {
                        display: block;
                        font-size: 1.7em;
                        font-weight: 300;
                        color: $white;
                        text-transform: uppercase;
                        text-align: center;
                    }
                    &.social {
                        a {
                            color: $white;
                            i.fa { display: none; }
                            &.social-fb:after { content: ' Facebook'; }
                            &.social-gp:after { content: ' Google+'; }
                        }
                    }
                }

                .close {
                    position: absolute;
                    top: 1em;
                    right: 1em;
                    z-index: 201;
                    font-family: "FontAwesome";
                    font-size: 3em;
                    color: $white;
                    cursor: pointer;
                }
            }

            .menu-toggler {
                display: block;
                cursor: pointer;
            }
        }
    }

    .featured {
        .featured-img {
            max-height: 300px;
            &:after { height: 300px; }

            h1 {
                font-size: 1.8em;
                top: 169px;
            }

            h2 {
                font-size: 1.8em;
                top: 214px;
                right: 40px;
            }

            img { max-height: 300px; }
        }
    }

    .about {
        .about-left {
            width: 185px;
        }
    }

    .category-list {
        .category-item {
            width: 100%;
        }
    }

    .title-parallax-wrapper {
        &.product-single-parallax-wrapper {
            font-size: 1em;
        }
    }

}

@media only screen and (max-width: 600px) {

    .site-header {
        .header-top {
            .contact-info { width: 100%; text-align: right; }
            .division-links { display: none; }
        }
    }

    .title-parallax-wrapper {
        height: 280px;
        &.references-parallax-wrapper { height: 115px; }

        .wrapper { height: 115px; }
        h1.page-title { font-size: 2.5em; }
    }

    h1.reference-title {
        font-size: 1.7em;
    }

    .page-body {
        font-size: 14px;

        p, div { font-size: 14px; }

        h2 { font-size: 1.6em; }
        h3 { font-size: 1.4em; }
        h4 { font-size: 1.3em; }
        h5 { font-size: 1.2em; }
        h6 { font-size: 1.1em; }
    }

    .category-list {
        .category-item {
            .category-image {
                display: none;
            }

            ul {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                li {
                    width: 49%;

                    a {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        min-height: 50px;
                        padding: .5em;

                        h3 {
                            flex: 1;
                            display: block;
                            font-size: 1.1em;
                            text-align: left;
                            padding-left: .5em;
                        }

                        .product-image {
                            width: 95px;
                            height: 95px;
                            display: flex;
                            align-items: center;
                            background: $white;
                            margin-right: .5em;
                            img { flex-basis: 95px; width: 95px; height: 95px; }
                        }
                    }
                }
            }
        }
    }

    .ref-columns {
        flex-wrap: wrap;
        .ref {
            width: 100%;
            display: flex;

            .ref-img {
                width: 40%;
            }

            .ref-text {
                flex: 1;

                h3 { font-size: 1.6em; margin-bottom: .75em; }
                p { font-size: 1.1em; line-height: 1.5em; }
            }
        }
    }

    .reference-masonry {
        a {
            width: 29%;
            margin: 2%;
        }
    }

    .references {
        .more-refs .btn { width: auto; max-width: 100%; font-size: 1.2em; }
    }

    .contact-form {
        width: 45%;
    }

    .site-footer {
        .footer-img { display: none; }

        .footer-menu {
            ul.nav-menu {
                li {
                    margin-right: 1em;
                    a { font-size: .9em; }
                }
            }
        }
    }

}

@media only screen and (max-width: 480px) {

    .about {
        .wrapper {
            flex-wrap: wrap-reverse;
        }

        .about-left {
            width: 100%;
            padding: .5em 25%;
            margin: -6em 0 2em 0;
            flex: 1 0 auto;
        }

        .about-right {
            width: 100%;
            flex: 1 0 auto;
        }
    }

    .about-pageimage-1 { max-width: 150px !important; margin-top: 2em !important; }
    .about-pageimage-2 { width: 160px !important; }

    h1.reference-title {
        font-size: 1.4em;
        padding: 1em 0;
    }

    .map-container {
        height: 200px;
        #map { height: 200px; }
    }

    .page-contact-single {
        .wrapper {
            display: block;
        }

        .page-body {
            width: 100%;
            margin-bottom: 2em;
        }

        .contact-form {
            width: 100%;
            margin: 2em 0;
        }
    }

    .category-list {
        .category-item {
            ul {
                li {
                    width: 100%;
                    margin: .25em 0;
                    a {
                        h3 { font-size: 1.3em; }
                    }
                }
            }
        }
    }

    .title-parallax-wrapper {
        &.product-single-parallax-wrapper {
            font-size: .8em;
        }
    }

    .product-single-container {
        .product-page {
            display: block;

            .product-page-body {
                width: 100%;
                display: block;

                .product-table {
                    td {
                        font-size: .9em;
                        padding: .7em;
                    }
                }
            }

            .product-sidebar {
                width: 100%;
                display: block;
                text-align: center;
                margin: 0 0 2em 0;
                padding: 0 10%;
            }
        }
    }

    .reference-masonry {
        a {
            width: 46%;
            margin: .5em;
        }
    }

}

@media only screen and (max-width: 380px) {

    .site-header {
        .header-top {
            padding: 1em 0;
        }

        .header-menu {
            .logo {
                width: 245px;
                height: 68px;
            }

            ul.nav-menu {
                li {
                    a { font-size: 1.4em; font-weight: bold; }
                }
            }
        }
    }

    .featured {
        .featured-img {
            max-height: 220px;
            &:after { height: 220px; }

            img { max-height: 220px; }
            h1 { font-size: 1.3em; top: 123px; }
            h2 { font-size: 1.3em; top: 155px; height: 29px; }
        }
    }

    .reference-masonry {
        a { width: 100%; }
    }

    .about-pageimage-1 { max-width: 100px !important; margin-top: 2em !important; }
    .about-pageimage-2 { width: 110px !important; }

    .contact-link {
        a { display: block; font-size: 1em; text-align: center; }
    }

    .testimonials {
        background: $white;
        padding-bottom: 2em;
    }

}

@media only screen and (max-width: 320px) {

    .site-header {
        .header-top {
            padding: .35em 0;
            .contact-info {
                a { display: block; line-height: 2em; }
            }
        }

        .header-menu {
            ul.nav-menu {
                .close { top: .35em; right: .35em; }
            }
        }
    }

    .about {
        .about-left {
            margin-top: -3em;
            padding-left: 5%;
            padding-right: 5%;
        }
    }

    .site-footer {
        .footer-menu {
            ul.nav-menu {
                text-align: center;
                li {
                    display: inline-block;
                    a {
                        line-height: 2.5em;
                    }
                }
            }
        }
    }

}