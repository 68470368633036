// Buttons
.btn {
    display: inline-block;
    font: bold 1em "Open Sans", Helvetica, Arial, sans-serif;
    color: $white;
    background: $blue;
    border: none;
    border-radius: 0;
    outline: none;
    margin: 0;
    padding: 1.2em 2em;
    text-align: center;
    text-decoration: none;
    &:hover, &:focus { text-decoration: none !important; }

    &.btn-primary {
        background: $blue;
        color: $white;
        font-size: 1.2em;
        &:hover { background: lighten($blue, 7%); }
    }

    &.btn-default {
        background: $gray;
        border: 3px solid darken($gray, 25%);
        color: $black;
        &:hover { color: $red; border: 3px solid darken($gray, 50%); }
        i.fa { color: $red; }
    }

    &.btn-empty {
        background: none;
        color: $blue-dark;
    }
}

// Alerts
.alert {
    display: block;
    line-height: 1.5em;
    font-weight: bold;
    color: $black;
    margin: .5em 0;
    padding: .5em 1em;

    &.alert-success {
        color: $green;
        p, h3 { color: $green; }
    }

    &.alert-danger {
        color: $red;
        p, h3 { color: $red; }
    }

    &.alert-success-border { border: 3px solid $green; }
    &.alert-danger-border { border: 3px solid $red; }

    ul li { list-style: none; }
}