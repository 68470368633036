* {
    margin: 0;
}

html, body {
    height: 100%;
}

.full-width {
    width: 100%;
    display: block;
}

.page-wrapper {
    min-height: 100%;
    margin-bottom: -196px;
    &:after { content: ''; display: block; height: 196px; }
}

.wrapper {
    width: 1100px;
    position: relative;
    display: block;
    margin: 0 auto;
    text-align: left;
}

.pull-left { float: left; }
.pull-right { float: right; }

body {
    height: 100%;
    background: url(../img/gray-pattern-1.png) #e8e8e8;
    font: normal 14px "Open Sans", Helvetica, sans-serif;
    color: $black;
    text-align: center;
}

// Header section
.site-header {
    width: 100%;
    position: relative;
    z-index: 50;
    display: block;

    .header-top {
        padding: .75em 0;
        background: $gray;
        font-size: 13px;

        .wrapper {
            display: flex;
            justify-content: space-between;
        }

        .contact-info {
            padding-left: 90px;
            a {
                color: $black;
                i.fa { color: $blue; }
                &:hover { text-decoration: none; }
                &:nth-child(2) { padding-left: 30px; }
            }
        }

        .division-links {
            a {
                padding-left: 30px;
                &:nth-child(1) { color: $red; }
                &:nth-child(2) { color: $yellow; }
            }
        }
    }

    .header-menu {
        background: $white;

        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: .5em 0;
        }

        .logo {
            width: 360px;
            height: 100px;
            display: inline-block;
            margin-top: -10px;
            margin-bottom: -10px;
            background: url(../img/logo2x.png) no-repeat;
            background-size: cover;
        }

        ul.nav-menu {
            list-style: none;
            text-align: right;

            li {
                display: inline-block;
                margin: 0 1em;
                &:last-child { margin-right: 0; }
            }
        }

        .menu-toggler {
            width: 0;
            height: 0;
            position: relative;
            display: none;
            margin: -.25em 2em 1em 2em;
            &:after {
                content: "\f0c9";
                font-family: "FontAwesome";
                font-size: 1.5em;
                color: $blue;
            }
        }
    }
}

// Footer section
.site-footer {
    width: 100%;
    height: 165px;
    display: block;
    border-top: 1px solid $gray;
    margin-top: 20px;

    .footer-logos {
        background: $white;
        .wrapper {
            text-align: center;
            .footer-img {
                margin: 0 auto;
                padding: 1em 0;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .footer-menu {
        background: $blue-dark;
        color: $white;
        padding: .75em 0;

        ul.nav-menu {
            list-style: none;

            li {
                display: inline-block;
                margin-right: 2em;
                a {
                    display: block;
                    color: $white;
                    font-size: 15px;
                }
            }
        }
    }
}