a {
    text-decoration: none;
    color: $black;
    &:focus, &:hover {
        text-decoration: underline;
    }
}

.font-bold { font-weight: bold; }
.font-light { font-weight: 300; }

.blue { color: $blue; }
.yellow { color: $yellow; }
.red { color: $red; }

.page-body {
    div, p {
        color: tint($black, 10%);
        font-size: 17px;
        font-weight: normal;
        line-height: 1.6em;
        margin-bottom: 1em;
    }

    h2, h3, h4, h5, h6 {
        color: $blue;
        font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
        font-weight: bold;
        line-height: 1.6em;
        margin-top: .7em;
        margin-bottom: .5em;
        a {
            text-decoration: none;
            &:hover { text-decoration: underline; }
        }
    }

    h2 { font-size: 2.2em; }
    h3 { font-size: 1.95em; }
    h4 { font-size: 1.8em; color: $black; }
    h5 { font-size: 1.7em; color: $black; }
    h6 { font-size: 1.5em; color: $black; }

    ul, ol {
        width: 96%;
        margin: .8em 0 1.2em 4%;
    }

    ul li, ol li {
        list-style-type: disc;
        font-weight: normal;
    }

    ol li { list-style-type: decimal; }
    ul li {
        list-style: none;
        padding: .35em 0;
        &:before {
            margin-left: -1em;
            margin-right: .5em;
            content: '\f105';
            color: $black;
            font-family: "FontAwesome";
        }
    }

    a {
        color: $black;
        text-decoration: underline;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin: .8em 0 1.2em 0;
    }

    table td,
    table th {
        font-size: 1em;
        padding: .5em;
        border-bottom: 1px solid #e1e1e1;
        vertical-align: middle;
    }
    
    img {
        max-width: 80%;
        display: inline-block;
    }

    img[style*="float: left"] {
        margin: 0 1em 1em 0;
    }

    img[style*="float: right"] {
        margin: 0 0 1em 1em;
    }
}