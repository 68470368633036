// Site herospace
.featured {
    width: 100%;
    position: relative;
    z-index: 10;
    display: block;
    margin-top: -7px;

    .featured-img {
        position: relative;
        max-height: 558px;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 558px;
            background-image: url(../img/featured-bg.png);
            background-size: cover;
            background-position: center center;
            z-index: 100;
        }
        img {
            margin: 0 auto;
            width: auto;
            min-width: 100%;
            max-height: 558px;
        }
        h1 {
            position: absolute;
            top: 312px;
            width: 100%;
            font-size: 3em;
            text-align: center;
            text-transform: uppercase;
            z-index: 200;
        }
        h2 {
            position: absolute;
            top: 390px;
            right: 90px;
            width: 100%;
            font-size: 3em;
            text-align: center;
            text-transform: uppercase;
            color: $white;
            z-index: 200;
        }
    }
}

// Introduction
.about {
    background: $white;

    .wrapper {
        display: flex;
        justify-content: space-between;
        padding: 1.5em 0;
    }

    .about-left {
        width: 220px;
        margin: 1.5em 3.5em 0 0;

        img {
            max-width: 100%;
            display: block;
        }

        .btn {
            position: relative;
            z-index: 5;
            display: block;
            margin-top: -1.75em;
        }
    }

    .about-right {
        flex: 1;

        .about-sign {
            text-align: right;
            margin: .5em 2em 0 0;

            img {
                margin-top: -.25em;
            }
        }
    }
}

.references {
    padding: 20px 0 30px 0;
    margin-bottom: -2em;

    .references-title {
        font: 300 40px "Roboto Slab", Helvetica, Arial, sans-serif;
        color: $blue;
        margin-bottom: .5em;
    }

    .more-refs {
        display: block;

        .btn {
            display: block;
            width: 50%;
            margin: 30px auto;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            border: 4px solid $gray-dark;
            border-radius: 4px;
            padding: 15px;
            font-size: 22px;
            font-weight: 600;
            color: $gray-dark;
            transition: all .35s ease-in-out;
            &:hover, &:focus {
                color: $blue;
                border-color: $blue;
            }
        }
    }
}