$slick-loader-path: "../img/";
$slick-font-path: "../fonts/";

@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,300&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700&subset=latin,latin-ext);

@import "./bower_components/font-awesome/scss/font-awesome";
@import "./bower_components/md-compass-mixins/lib/compass";
// @import "./bower_components/slick-carousel/slick/slick.scss";
// @import "./bower_components/slick-carousel/slick/slick-theme.scss";

@import "variables";
@import "reset";
@import "ui";
@import "lightbox";
@import "qtip";
@import "type";
@import "layout";
@import "main";
@import "page";
@import "product";
@import "reference";

@import "mediaqueries";