// Breadcrumb navigation
.breadcrumb {
    width: 100%;
    display: block;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    padding: .35em 0;
    margin-top: -.35em;
    margin-bottom: 1em;

    span {
        display: inline-block;
        font-size: .85em;
        color: lighten($black, 25%);
        &:after {
            content: '\f105';
            font-family: "FontAwesome";
            color: lighten($black, 10%);
            margin: 0 .5em 0 1em;
        }
        &:last-child:after { display: none; }
    }
}

// Single page view
h1.page-title {
    width: 100%;
    display: block;
    font: bold 3em "Roboto Slab", Helvetiva, Arial, sans-serif;
    color: $blue;
    padding: .5em 0;
    text-align: center;
}

.title-wrapper {
    width: 100%;
    display: block;
    background: lighten($gray, 3%);
}

.title-parallax-wrapper {
    width: 100%;
    height: 400px;
    display: block;
    background: url(../img/title-bg-3.jpg) no-repeat;
    background-attachment: fixed;

    .wrapper {
        display: flex;
        height: 220px;
        align-items: center;
        text-align: left;
    }

    h1.page-title {
        font-size: 3.5em;
        color: $white;
        text-shadow: 1px 1px 50px rgba($black, .85);
        text-align: left;
    }
}

.page-single {
    width: 100%;
    display: block;
    padding: 1.5em 0;
    border-top: 3px solid darken($gray, 7%);

    &.page-single-parallax {
        border-top: none;

        .wrapper {
            background: rgba($white, .95);
            margin-top: -200px;
            padding: .75em 2.5em;
            border-top: 10px solid $blue;
        }
    }

    .error-face {
        display: block;
        text-align: center;
        i.fa { font-size: 5em; color: #ddd; }
    }
}

.about-bullets {
    display: block;
    margin-top: 1em;

    p {
        position: relative;
        padding-left: 2.5em;
        &:before {
            content: "\f054";
            font-family: "FontAwesome";
            color: $blue;
            position: absolute;
            top: .15em;
            left: 1em;
        }
    }
}

.testimonials {
    display: block;
    margin: 3em 0 4em 0;
    text-align: right;

    article {
        display: block;
        margin-bottom: 2em;

        .quote {
            font-family: "Georgia", serif;
            font-size: 1.4em;
            font-style: italic;
            margin-bottom: .25em;
            color: lighten($black, 10%);
            &:before { content: '"'; }
            &:after { content: '"'; }
        }

        .author {
            font-size: .8em;
            font-weight: bold;
            line-height: 1.1em;
            color: $blue;
        }
    }
}

// Contact page
.map-container {
    width: 100%;
    height: 320px;
    display: block;
    position: relative;
    z-index: 30;
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 70;
    }

    #map { width: 100%; height: 320px; }
}

// Contact form
.page-contact-single {
    background: $white;
    margin-bottom: .75em;

    .wrapper {
        display: flex;
        justify-content: space-between;
    }

    .page-body {
        flex: 1;
    }
}

.map-link {
    display: block;
    margin-top: 2em;

    a {
        font: bold 1.1em "Roboto Slab", Helvetica, Arial, sans-serif;
        color: $blue;
        background: $gray;
        padding: .5em 1em;
        text-decoration: none;
        &:hover {
            background: lighten($gray, 3%);
        }
        &:before {
            content: '\f041';
            font-family: "FontAwesome";
            display: inline-block;
            margin-right: .5em;
        }
    }
}

.contact-link {
    display: block;

    a {
        display: inline-block;
        font: bold 1.2em "Roboto Slab", Helvetica, Arial, sans-serif;
        color: $red;
        background: $gray;
        margin: 1em;
        padding: 1em 1.5em;
        text-decoration: none;
        &:hover {
            background: lighten($gray, 3%);
        }
        &:before {
            content: '\f041';
            font-family: "FontAwesome";
            display: inline-block;
            margin-right: .5em;
            color: $red;
        }
    }
}

.contact-form {
    width: 360px;
    position: relative;
    z-index: 100;
    background: lighten($gray, 7%);
    border: 3px solid $gray;
    padding: 1em;
    margin-top: -7em;

    h3 {
        display: block;
        font-size: 1.6em;
        font-weight: bold;
        color: $blue;
        margin-bottom: .5em;
    }

    p small {
        display: block;
        margin: 0 0 1.5em;
        color: lighten($black, 10%);
    }

    .form-group {
        display: block;
        margin: .75em 0;

        label {
            display: block;
            font-size: .85em;
            font-weight: bold;
            margin-bottom: .35em;
            em { color: orangered; }
        }

        .form-input {
            width: 100%;
            height: 36px;
            background: $white;
            border: 1px solid $gray;
            font: normal 14px "Open Sans", Helvetica, Arial, sans-serif;
            color: #444;
            padding: 0 .5em;
            outline: none;
        }

        textarea.form-input {
            height: 100px;
            padding-top: .5em;
            padding-bottom: .5em;
            resize: none;
        }

        &.has-error {
            label { color: darkred; }
            .form-input { color: darkred; border: 1px solid tint(darkred, 25%); }
            textarea.form-input { color: darkred; border: 1px solid tint(darkred, 25%); }
        }
    }

    .btn {
        font-size: 1.1em;
        padding: .6em 1.2em;
        cursor: pointer;
    }
}