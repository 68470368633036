.products-container {
    width: 100%;
    display: block;
    padding: 2em 0;

    &.products-parallax-container {
        .wrapper {
            background: rgba($white, .95);
            margin-top: -200px;
            padding: .5em 1.5em;
            border-top: 7px solid $blue;
        }
    }
}

.product-parallax-wrapper {
    background-image: url(../img/title-bg-6.jpg);
}

.product-single-parallax-wrapper {
    background-image: url(../img/title-bg-6.jpg);

    .page-title a { color: $white; font-weight: 300; }
}

// Categories
.category-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .category-item {
        width: 49%;
        display: flex;
        justify-content: space-between;
        border: 4px solid #ddd;
        margin: .75em 0;
        padding: .75em;

        .category-image {
            width: 140px;
            height: 140px;
            flex-basis: 140px;
            display: inline-block;
            margin-right: 1.25em;
        }

        .category-data {
            flex: 1;
            padding-top: .5em;
        }

        h2 {
            display: block;
            font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
            font-weight: bold;
            font-size: 1.7em;
            color: $blue;
            margin-bottom: .5em;
        }

        ul {
            list-style: none;

            li {
                display: inline-block;
                line-height: 2em;

                a {
                    display: block;
                    font-size: 1em;
                    background: #eee;
                    border: 1px solid #ddd;
                    margin: .35em .35em .35em 0;
                    padding: .25em .6em;
                    &:hover {
                        background: #f7f7f7;
                        text-decoration: none;
                    }

                    .product-image { display: none; }
                }
            }
        }
    }
}

.product-single-container {
    width: 100%;
    display: block;
    padding: 1.5em 0;
    background: $white;

    .product-page {
        display: flex;
        justify-content: space-between;
    }

    .product-sidebar {
        width: 29%;
        margin-left: 2em;

        .btn-download {
            font-size: 1.3em;
            display: block;
        }
    }

    .product-lead-image {
        display: block;
        margin-bottom: 2em;
        img { width: 100%; height: auto; display: block; }
    }

    .product-page-body {
        flex: 1;
        display: block;
        margin-bottom: 1em;
        font-size: 1.1em;
        overflow: auto;

        p, div {
            font-size: 1.1em;
        }

        .product-table {
            width: 100%;
            margin: 1.5em 0;

            tbody {
                tr {
                    td {
                        padding: 1em;
                        background: $white;
                        border: 1px solid lighten($gray, 3%);
                        &:nth-of-type(odd) {
                            font-weight: bold;
                        }
                    }

                    &:nth-of-type(even) {
                        td { background: lighten($gray, 3%); }
                    }
                }
            }
        }
    }
}