.references-container {
    width: 100%;
    display: block;
    padding: 1.5em 0;
}

.ref-columns {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .ref {
        width: 30%;
        margin: 1em 1em 1.5em 1em;
        background: $white;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        &:nth-child(1n+1) { margin-left: 0; }
        &:nth-child(3n+3) { margin-right: 0; }

        .ref-img {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;

            img {
                flex: 1;
                min-width: 100%;
                min-height: 200px;
                border: none;
            }

            .ref-icon {
                position: absolute;
                left: -30px;
                bottom: -80px;
                background-color: $blue;
                padding: 80px 15px 80px 50px;
                transform: rotate(-45deg);
                i {
                    color: $white;
                    transform: rotate(45deg);
                }
            }

            &:hover {
                img {
                    transform: scale(1.05) rotate(1deg);
                    transition: all .35s ease-in-out;
                }

                .ref-icon {
                    background: darken($blue, 10%);
                    transition: background .35s ease-in-out;
                }
            }
        }

        .ref-text {
            display: block;
            padding: 1.25em;

            h3 {
                display: block;
                margin-bottom: .25em;
                font: bold 1.2em/1.3em "Roboto Slab", Helvetica, Arial, sans-serif;
                a {
                    color: $black;
                    &:hover { text-decoration: underline; }
                }
            }

            p {
                display: block;
                font-size: 1em;
                line-height: 1.3em;
                margin: .25em 0;
            }
        }
    }
}

.reference-home {
    .wrapper {
        display: flex;
        justify-content: space-between;
    }

    //.reference-list {
    //    width: 25%;
    //    margin-top: -.5em;
    //    margin-right: 2em;
    //
    //    ul {
    //        list-style: none;
    //        li {
    //            display: block;
    //            margin: .5em 0;
    //            a {
    //                display: block;
    //                font-size: .95em;
    //                font-weight: bold;
    //                line-height: 1.5em;
    //                padding: .75em;
    //                background: lighten($gray, 5%);
    //                &:hover {
    //                    text-decoration: none;
    //                    background: $black;
    //                    color: $white;
    //                }
    //            }
    //            &.selected {
    //                a {
    //                    background: $black;
    //                    color: $white;
    //                }
    //            }
    //        }
    //    }
    //}

    .ref-columns {
        flex: 1;
        width: 100%;
        flex-wrap: wrap;
    }
}

h1.reference-title {
    text-align: left;
    font-size: 2.25em;

    a {
        font-weight: 300;
        color: lighten(tint($black, 30%), 5%);
    }
}

.references-parallax-wrapper {
    height: 180px;
    background-image: url(../img/title-bg-7.jpg);

    .wrapper {
        height: 180px;
    }
}

.reference-single-container {
    width: 100%;
    display: block;
    padding: 1.5em 0;

    .page-body {
        display: block;
        margin-bottom: 1em;
        font-size: 1.3em;

        p, div { font-size: 1.3em; }
    }
}

.reference-masonry {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    a {
        width: 22%;
        position: relative;
        display: inline-block;
        border: 5px solid lighten($gray, 5%);
        margin: 1em;
        &:hover {
            &:before {
                content: '\f00e';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba($black, .35);
                font-family: "FontAwesome";
                font-size: 60px;
                color: $white;
                text-align: center;
                line-height: 4em;
            }
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}